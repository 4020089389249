/* *
 *
 *  Highcharts Drilldown module
 *
 *  Author: Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

/* *
 *
 *  API Options
 *
 * */
/**
 * Options for drill down, the concept of inspecting increasingly high
 * resolution data through clicking on chart items like columns or pie slices.
 *
 * The drilldown feature requires the drilldown.js file to be loaded,
 * found in the modules directory of the download package, or online at
 * [code.highcharts.com/modules/drilldown.js
 * ](https://code.highcharts.com/modules/drilldown.js).
 *
 * @sample {highcharts} highcharts/series-organization/drilldown
 *         Organization chart drilldown
 *
 * @product      highcharts highmaps
 * @requires     modules/drilldown
 * @optionparent drilldown
 */
const DrilldownDefaults = {
  /**
   * When this option is false, clicking a single point will drill down
   * all points in the same category, equivalent to clicking the X axis
   * label.
   *
   * @sample {highcharts} highcharts/drilldown/allowpointdrilldown-false/
   *         Don't allow point drilldown
   *
   * @type      {boolean}
   * @default   true
   * @since     4.1.7
   * @product   highcharts
   * @apioption drilldown.allowPointDrilldown
   */
  /**
   * Options for the breadcrumbs, the navigation at the top leading the way
   * up through the drilldown levels.
   *
   * @since 10.0.0
   * @product   highcharts
   * @extends   navigation.breadcrumbs
   * @optionparent drilldown.breadcrumbs
   */
  /**
   * An array of series configurations for the drill down. Each series
   * configuration uses the same syntax as the [series](#series) option set.
   * These drilldown series are hidden by default. The drilldown series is
   * linked to the parent series' point by its `id`.
   *
   * @type      {Array<Highcharts.SeriesOptionsType>}
   * @since     3.0.8
   * @product   highcharts highmaps
   * @apioption drilldown.series
   */
  /**
   * Additional styles to apply to the X axis label for a point that
   * has drilldown data. By default it is underlined and blue to invite
   * to interaction.
   *
   * In styled mode, active label styles can be set with the
   * `.highcharts-drilldown-axis-label` class.
   *
   * @sample {highcharts} highcharts/drilldown/labels/
   *         Label styles
   *
   * @type    {Highcharts.CSSObject}
   * @default { "cursor": "pointer", "color": "#003399", "fontWeight": "bold", "textDecoration": "underline" }
   * @since   3.0.8
   * @product highcharts highmaps
   */
  activeAxisLabelStyle: {
    /** @ignore-option */
    cursor: 'pointer',
    /** @ignore-option */
    color: "#0022ff" /* Palette.highlightColor100 */,

    /** @ignore-option */
    fontWeight: 'bold',
    /** @ignore-option */
    textDecoration: 'underline'
  },
  /**
   * Additional styles to apply to the data label of a point that has
   * drilldown data. By default it is underlined and blue to invite to
   * interaction.
   *
   * In styled mode, active data label styles can be applied with the
   * `.highcharts-drilldown-data-label` class.
   *
   * @sample {highcharts} highcharts/drilldown/labels/
   *         Label styles
   *
   * @type    {Highcharts.CSSObject}
   * @default { "cursor": "pointer", "color": "#003399", "fontWeight": "bold", "textDecoration": "underline" }
   * @since   3.0.8
   * @product highcharts highmaps
   */
  activeDataLabelStyle: {
    cursor: 'pointer',
    color: "#0022ff" /* Palette.highlightColor100 */,
    fontWeight: 'bold',
    textDecoration: 'underline'
  },
  /**
   * Set the animation for all drilldown animations. Animation of a drilldown
   * occurs when drilling between a column point and a column series,
   * or a pie slice and a full pie series. Drilldown can still be used
   * between series and points of different types, but animation will
   * not occur.
   *
   * The animation can either be set as a boolean or a configuration
   * object. If `true`, it will use the 'swing' jQuery easing and a duration
   * of 500 ms. If used as a configuration object, the following properties
   * are supported:
   *
   * - `duration`: The duration of the animation in milliseconds.
   *
   * - `easing`: A string reference to an easing function set on the `Math`
   *   object. See
   *   [the easing demo](https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/plotoptions/series-animation-easing/).
   *
   * @type    {boolean|Highcharts.AnimationOptionsObject}
   * @since   3.0.8
   * @product highcharts highmaps
   */
  animation: {
    /** @ignore-option */
    duration: 500
  },
  /**
   *
   * Options for the drill up button that appears when drilling down on a
   * series. The text for the button is defined in
   * [lang.drillUpText](#lang.drillUpText).
   *
   * This option is deprecated since 9.3.2, use `drilldown.breadcrumbs`
   * instead.
   *
   * @sample highcharts/breadcrumbs/single-button
   *         Breadcrumbs set up like a legacy button
   * @sample {highcharts} highcharts/drilldown/drillupbutton/ Drill up button
   * @sample {highmaps} highcharts/drilldown/drillupbutton/ Drill up button
   *
   * @since   3.0.8
   * @product highcharts highmaps
   *
   * @deprecated
   */
  drillUpButton: {
    /**
     * What box to align the button to. Can be either `plotBox` or
     * `spacingBox`.
     *
     * @type       {Highcharts.ButtonRelativeToValue}
     * @default    plotBox
     * @since      3.0.8
     * @product    highcharts highmaps
     * @apioption  drilldown.drillUpButton.relativeTo
     */
    /**
     * A collection of attributes for the button. The object takes SVG
     * attributes like `fill`, `stroke`, `stroke-width` or `r`, the border
     * radius. The theme also supports `style`, a collection of CSS
     * properties for the text. Equivalent attributes for the hover state
     * are given in `theme.states.hover`.
     *
     * In styled mode, drill-up button styles can be applied with the
     * `.highcharts-drillup-button` class.
     *
     * @sample {highcharts} highcharts/drilldown/drillupbutton/
     *         Button theming
     * @sample {highmaps} highcharts/drilldown/drillupbutton/
     *         Button theming
     *
     * @type      {Object}
     * @since     3.0.8
     * @product   highcharts highmaps
     * @apioption drilldown.drillUpButton.theme
     */
    /**
     * Positioning options for the button within the `relativeTo` box.
     * Available properties are `x`, `y`, `align` and `verticalAlign`.
     *
     * @type    {Highcharts.AlignObject}
     * @since   3.0.8
     * @product highcharts highmaps
     */
    position: {
      /**
       * Vertical alignment of the button.
       *
       * @type      {Highcharts.VerticalAlignValue}
       * @default   top
       * @product   highcharts highmaps
       * @apioption drilldown.drillUpButton.position.verticalAlign
       */
      /**
       * Horizontal alignment.
       *
       * @type {Highcharts.AlignValue}
       */
      align: 'right',
      /**
       * The X offset of the button.
       */
      x: -10,
      /**
       * The Y offset of the button.
       */
      y: 10
    }
  },
  /**
   * Enable or disable zooming into a region of clicked map point you want to
   * drill into. If mapZooming is set to false the drilldown/drillup
   * animations only fade in/fade out without zooming to a specific map point.
   *
   * @sample    maps/demo/map-drilldown-preloaded/
   *            Map drilldown without async maps loading
   *
   * @type      {boolean}
   * @default   true
   * @since 11.0.0
   * @product   highmaps
   * @apioption drilldown.mapZooming
   */
  mapZooming: true
};
/**
 * Fires when a drilldown point is clicked, before the new series is added. This
 * event is also utilized for async drilldown, where the seriesOptions are not
 * added by option, but rather loaded async. Note that when clicking a category
 * label to trigger multiple series drilldown, one `drilldown` event is
 * triggered per point in the category.
 *
 * Event arguments:
 *
 * - `category`: If a category label was clicked, which index.
 *
 * - `originalEvent`: The original browser event (usually click) that triggered
 *   the drilldown.
 *
 * - `point`: The originating point.
 *
 * - `points`: If a category label was clicked, this array holds all points
 *   corresponding to the category.
 *
 * - `seriesOptions`: Options for the new series.
 *
 * @sample {highcharts} highcharts/drilldown/async/
 *         Async drilldown
 *
 * @type      {Highcharts.DrilldownCallbackFunction}
 * @since     3.0.8
 * @product   highcharts highmaps
 * @context   Highcharts.Chart
 * @requires  modules/drilldown
 * @apioption chart.events.drilldown
 */
/**
 * Fires when drilling up from a drilldown series.
 *
 * @type      {Highcharts.DrillupCallbackFunction}
 * @since     3.0.8
 * @product   highcharts highmaps
 * @context   Highcharts.Chart
 * @requires  modules/drilldown
 * @apioption chart.events.drillup
 */
/**
 * In a chart with multiple drilldown series, this event fires after all the
 * series have been drilled up.
 *
 * @type      {Highcharts.DrillupAllCallbackFunction}
 * @since     4.2.4
 * @product   highcharts highmaps
 * @context   Highcharts.Chart
 * @requires  modules/drilldown
 * @apioption chart.events.drillupall
 */
/**
 * The `id` of a series in the [drilldown.series](#drilldown.series) array to
 * use for a drilldown for this point.
 *
 * @sample {highcharts} highcharts/drilldown/basic/
 *         Basic drilldown
 *
 * @type      {string}
 * @since     3.0.8
 * @product   highcharts
 * @requires  modules/drilldown
 * @apioption series.line.data.drilldown
 */
/**
 * The text for the button that appears when drilling down, linking back
 * to the parent series. The parent series' name is inserted for
 * `{series.name}`.
 *
 * @deprecated
 * @since    3.0.8
 * @product  highcharts highmaps
 * @requires modules/drilldown
 * @apioption lang.drillUpText
 */
''; // Keep doclets above detached in JS file
/* *
 *
 *  Default Export
 *
 * */
export default DrilldownDefaults;