/* *
 *
 *  Highcharts Breadcrumbs module
 *
 *  Authors: Grzegorz Blachlinski, Karol Kolodziej
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

/* *
 *
 *  Constants
 *
 * */
/**
 * @optionparent lang
 */
const lang = {
  /**
   * @since   10.0.0
   * @product highcharts
   *
   * @private
   */
  mainBreadcrumb: 'Main'
};
/**
 * Options for breadcrumbs. Breadcrumbs general options are defined in
 * `navigation.breadcrumbs`. Specific options for drilldown are set in
 * `drilldown.breadcrumbs` and for tree-like series traversing, in
 * `plotOptions[series].breadcrumbs`.
 *
 * @since        10.0.0
 * @product      highcharts
 * @optionparent navigation.breadcrumbs
 */
const options = {
  /**
   * A collection of attributes for the buttons. The object takes SVG
   * attributes like `fill`, `stroke`, `stroke-width`, as well as `style`,
   * a collection of CSS properties for the text.
   *
   * The object can also be extended with states, so you can set
   * presentational options for `hover`, `select` or `disabled` button
   * states.
   *
   * @sample {highcharts} highcharts/breadcrumbs/single-button
   *         Themed, single button
   *
   * @type    {Highcharts.SVGAttributes}
   * @since   10.0.0
   * @product highcharts
   */
  buttonTheme: {
    /** @ignore */
    fill: 'none',
    /** @ignore */
    height: 18,
    /** @ignore */
    padding: 2,
    /** @ignore */
    'stroke-width': 0,
    /** @ignore */
    zIndex: 7,
    /** @ignore */
    states: {
      select: {
        fill: 'none'
      }
    },
    style: {
      color: "#334eff" /* Palette.highlightColor80 */
    }
  },
  /**
   * The default padding for each button and separator in each direction.
   *
   * @type  {number}
   * @since 10.0.0
   */
  buttonSpacing: 5,
  /**
   * Fires when clicking on the breadcrumbs button. Two arguments are
   * passed to the function. First breadcrumb button as an SVG element.
   * Second is the breadcrumbs class, containing reference to the chart,
   * series etc.
   *
   * ```js
   * click: function(button, breadcrumbs) {
   *   console.log(button);
   * }
   * ```
   *
   * Return false to stop default buttons click action.
   *
   * @type      {Highcharts.BreadcrumbsClickCallbackFunction}
   * @since     10.0.0
   * @apioption navigation.breadcrumbs.events.click
   */
  /**
   * When the breadcrumbs are floating, the plot area will not move to
   * make space for it. By default, the chart will not make space for the
   * buttons. This property won't work when positioned in the middle.
   *
   * @sample highcharts/breadcrumbs/single-button
   *         Floating button
   *
   * @type  {boolean}
   * @since 10.0.0
   */
  floating: false,
  /**
   * A format string for the breadcrumbs button. Variables are enclosed by
   * curly brackets. Available values are passed in the declared point
   * options.
   *
   * @type      {string|undefined}
   * @since 10.0.0
   * @default   undefined
   * @sample {highcharts} highcharts/breadcrumbs/format Display custom
   *          values in breadcrumb button.
   */
  format: void 0,
  /**
   * Callback function to format the breadcrumb text from scratch.
   *
   * @type      {Highcharts.BreadcrumbsFormatterCallbackFunction}
   * @since     10.0.0
   * @default   undefined
   * @apioption navigation.breadcrumbs.formatter
   */
  /**
   * What box to align the button to. Can be either `plotBox` or
   * `spacingBox`.
   *
   * @type    {Highcharts.ButtonRelativeToValue}
   * @default plotBox
   * @since   10.0.0
   * @product highcharts highmaps
   */
  relativeTo: 'plotBox',
  /**
   * Whether to reverse the order of buttons. This is common in Arabic
   * and Hebrew.
   *
   * @sample {highcharts} highcharts/breadcrumbs/rtl
   *         Breadcrumbs in RTL
   *
   * @type  {boolean}
   * @since 10.2.0
   */
  rtl: false,
  /**
   * Positioning for the button row. The breadcrumbs buttons will be
   * aligned properly for the default chart layout (title,  subtitle,
   * legend, range selector) for the custom chart layout set the position
   * properties.
   *
   * @sample  {highcharts} highcharts/breadcrumbs/single-button
   *          Single, right aligned button
   *
   * @type    {Highcharts.BreadcrumbsAlignOptions}
   * @since   10.0.0
   * @product highcharts highmaps
   */
  position: {
    /**
     * Horizontal alignment of the breadcrumbs buttons.
     *
     * @type {Highcharts.AlignValue}
     */
    align: 'left',
    /**
     * Vertical alignment of the breadcrumbs buttons.
     *
     * @type {Highcharts.VerticalAlignValue}
     */
    verticalAlign: 'top',
    /**
     * The X offset of the breadcrumbs button group.
     *
     * @type {number}
     */
    x: 0,
    /**
     * The Y offset of the breadcrumbs button group. When `undefined`,
     * and `floating` is `false`, the `y` position is adapted so that
     * the breadcrumbs are rendered outside the target area.
     *
     * @type {number|undefined}
     */
    y: void 0
  },
  /**
   * Options object for Breadcrumbs separator.
   *
   * @since 10.0.0
   */
  separator: {
    /**
     * @type    {string}
     * @since   10.0.0
     * @product highcharts
     */
    text: '/',
    /**
     * CSS styles for the breadcrumbs separator.
     *
     * In styled mode, the breadcrumbs separators are styled by the
     * `.highcharts-separator` rule with its different states.
     *  @type  {Highcharts.CSSObject}
     *  @since 10.0.0
     */
    style: {
      color: "#666666" /* Palette.neutralColor60 */,
      fontSize: '0.8em'
    }
  },
  /**
   * Show full path or only a single button.
   *
   * @sample {highcharts} highcharts/breadcrumbs/single-button
   *         Single, styled button
   *
   * @type  {boolean}
   * @since 10.0.0
   */
  showFullPath: true,
  /**
   * CSS styles for all breadcrumbs.
   *
   * In styled mode, the breadcrumbs buttons are styled by the
   * `.highcharts-breadcrumbs-buttons .highcharts-button` rule with its
   * different states.
   *
   * @type  {Highcharts.SVGAttributes}
   * @since 10.0.0
   */
  style: {},
  /**
   * Whether to use HTML to render the breadcrumbs items texts.
   *
   * @type  {boolean}
   * @since 10.0.0
   */
  useHTML: false,
  /**
   * The z index of the breadcrumbs group.
   *
   * @type  {number}
   * @since 10.0.0
   */
  zIndex: 7
};
/* *
 *
 *  Default Export
 *
 * */
const BreadcrumbsDefaults = {
  lang,
  options
};
export default BreadcrumbsDefaults;